import { NullableString, UUID } from '@/models/Types';
import JobDetails from '@/models/JobDetails';
import CandidateStat from '@/models/CandidateStat';

export default interface JobAbstract {
    id: UUID;
    title: string;
    companyId: UUID | null;
    companyName: string;
    locationName: string;
    logo: NullableString;
    applyUrl: NullableString;
    status: number;
    listDate: Date | null;
    candidateStats: CandidateStat[];
    adExpiration: Date | null;
    industry: NullableString;
    notify: boolean;
}

export function createAbstract(job: JobDetails){
    const j: JobAbstract = {
                id: job.id!,
                title: job.title,
                companyId: job.companyId,
                companyName: job.company!.companyName,
                locationName: `${job.location!.city} ${job.location!.stateOrProvince}, ${job.location!.country}`,
                logo: job.company!.logo,
                applyUrl: null,
                status: job.status,
                listDate: null,
                candidateStats: [],
                adExpiration: null,
                industry: job.company!.industry,
                notify: job.notify
            }

    return j;
}