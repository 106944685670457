













import Vue from 'vue'

export default Vue.extend({
    name: 'ModalFooter',
    props: {
        cancelLabel: String,
        advanceLabel: String,
        invalid: { type: Boolean, required: false, default: false }
    },
    methods: {
        cancel() {
            this.$emit('onCancel');
        },
        advance() {
            this.$emit('onSubmit')
        }
    }
})
