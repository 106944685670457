


















import Vue from 'vue'
import JobAbstract from '@/models/JobAbstract';

export default Vue.extend({
    name: 'JobHeader',
    props: { 
        title: { type: String, required: true }, 
        job: { type: Object as () => JobAbstract, required: true }
    },
    methods: {
        close(){
            this.$emit('onClose'); //close the modal
        }
    }
})
