import { UUID } from './Types';

export default interface Candidate {
    id: UUID | null,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    postalCode: string,
    experience: CandidateExperience[],
    responses: CandidateResponse[],
    notes: CandidateNote[],
    smsConsent: boolean
}


export interface CandidateExperience {
    candidateId: UUID,
    rank: number,
    company: string,
    position: string,
    timeInPosition: string
}

export interface CandidateResponse {
    questionId: UUID,
    question: string,
    answer: string,
    weight: number,
    sortOrder: number
}

export interface CandidateNote {
    candidateId: UUID,
    rank: number,
    message: string,
    authorName: string,
    postedOn: Date,
    userId: UUID
}


export const DefaultCandidate: Candidate = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    postalCode: '',
    experience: [],
    responses: [],
    notes: [],
    smsConsent: false
}