































































































import Vue from 'vue';
import api from '@/api';
import Candidate, { CandidateExperience } from '@/models/Candidate';
import JobApplication from '@/models/JobApplication';
// components
import MaterialInput from '@/components/MaterialInput.vue';
import ModalFooter from '@/components/modals/ModalFooter.vue';
// validation
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} is required'
});


interface Data {
    dragging: Boolean;
    resumeView: Boolean;
}

export default Vue.extend({
    name: 'ApplicantExperience',
    components: {
        MaterialInput,
        ValidationProvider,
        ValidationObserver,
        ModalFooter
    },
    props: {
        app: { type: Object as () => JobApplication, required: true }
    },
    data(): Data {
        return {
            dragging: false,
            resumeView: true
        }
    },
    computed: {
        candidate(): Candidate {
            return this.app.candidate as Candidate;
        },
        lastExperience(): CandidateExperience | null {
            const len = this.candidate.experience.length;
            return len > 0 ? this.candidate.experience[len - 1] : null;
        },
        hasResume(): boolean {
            return this.app.resume != null;
        },
        isReady(): boolean {
            const exp = this.lastExperience;
            return this.hasResume || (exp != null && this.isValidExperience(exp));
        }
    },
    methods: {
        toggleView() {
            this.resumeView = !this.resumeView;

            // add one experience form by default
            if(!this.resumeView && this.candidate.experience.length == 0){ 
                this.addExperience();
            }
            
            // remove empty experience if toggling to resume
            if(this.resumeView && this.lastExperience != null && !this.isValidExperience(this.lastExperience)) {
                this.removeLastExperience();
            }
        },
        addExperience() {
            let idx = this.candidate.experience.length;
            this.candidate.experience.push({
                candidateId: this.candidate.id!,
                rank: idx,
                company: '',
                position: '',
                timeInPosition: ''
            });
        },
        removeLastExperience() {
            this.candidate.experience.pop();
        },
        isValidExperience(itm: CandidateExperience): boolean {
            return itm.company.length > 0 && itm.position.length > 0 && itm.timeInPosition.length > 0;
        },
        close() {
            this.$emit("onClose");
        },
        advance() {
            if(this.lastExperience != null && !this.isValidExperience(this.lastExperience)) {
                this.removeLastExperience();
            }
            this.$emit('onPost');
        },
        // form upload methods
        pickResume() {
            (this.$refs.file_upload as HTMLInputElement).click();
        },
        async dropResume(e: DragEvent) {
            this.dragging = false
            if (e.dataTransfer !== null && e.dataTransfer.files.length > 0) {
                const file = e.dataTransfer.files.item(0)!
                const formData = new FormData()
                formData.append("file", file, file.name)
                await this.uploadResume(formData);
            }
        },
        async resumeChanged(files: any) {
            if (files.length > 0) {
                const formData = new FormData();
                formData.append("file", files[0], files[0].name)
                await this.uploadResume(formData);
            }
        },
        async uploadResume(form: FormData){
            try {
                const rslt = await this.$http.post(`${api}/upload/resume`, form, { headers: {"Content-Type": "multipart/form-data"}});
                this.app.resume = rslt.data;

                this.$gtag.pageview({ page_path: '/quickapply/resume-upload' });

            } catch (error) {
                // eslint-disable-next-line
                console.error(error)
            }
        }
    }
})
