

























































import Vue from 'vue';
import api from '@/api';
import Candidate from '@/models/Candidate';
import JobApplication, { DefaultApplication } from '@/models/JobApplication';
// components
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialMaskedInput from '@/components/MaterialMaskedInput.vue';
import ModalFooter from '@/components/modals/ModalFooter.vue';
// validation
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, regex } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} is required'
});

extend('regex', {
    ...regex,
    message: '{_field_} is not in the expected format'
});

extend('email', {
    ...email,
    message: '{_field_} must be a valid email'
});

interface Data {
    prompt: string;
    instructions: string;
}

export default Vue.extend({
    name: 'ApplicantContactInfo',
    components: {
        MaterialInput,
        MaterialMaskedInput,
        ValidationObserver,
        ValidationProvider,
        ModalFooter
    },
    props: {
        jobId: { type: String, required: true },
        candidate: { type: Object as () => Candidate, required: true },
        preload: { type: Boolean, default: false }
    },
    data(): Data {
        return { 
            prompt: '',
            instructions: ''
        }
    },
    methods: {
        close() {
            this.$emit("onClose");
        },
        async advance() {
            if(!this.preload) {
                const prior = await this.detectDuplicates();
                this.$emit('onDuplicate', prior);
            } else {
                this.$emit('onPost')
            }
        },
        async detectDuplicates(): Promise<JobApplication|null> {

            try {
                // search for prior application
                const rslt = await this.$http.post(`${api}/candidates/find/${this.jobId}`, this.candidate);
                const prior = rslt.data as JobApplication | string;

                if(prior == '') {
                    return null;
                }

                return prior as JobApplication;

            } catch (error) {
                // eslint-disable-next-line
                console.error(`failed to run duplicate detection for ${this.jobId}.`);
                // eslint-disable-next-line
                console.error(error);

                return null;
            }
        }
    }
})
