





































import { UUID } from '@/models/Types';
import Vue from 'vue';
import api from '@/api';
import { QuestionVM } from '@/models/QualQuestion';
// components
import ModalFooter from '@/components/modals/ModalFooter.vue';
import { trackMetaPixelEvent } from '@/common/metaPixel'

interface Response {
    questionId: UUID,
    label: string,
    weight: number,
    sortOrder: number
}

interface Data {
    selected: Array<Response>
}

export default Vue.extend({
    name: 'ApplicantQualQuestions',
    components: {
        ModalFooter
    },
    props: {
        jobId: { type: String as () => UUID, required: true },
        candidateId: { type: String as () => UUID, required: true },
        questions: { type: Array as () => QuestionVM[], required: true }
    },
    data(): Data {
        return {
            selected: []
        }
    },
    methods: {
        close() {
            this.$emit("onClose");
        },
        async save() {
            trackMetaPixelEvent('QA_ApplicantQualQuestions_submit', true);
            try {
                if(this.selected.length > 0) {
                    const rslt = await this.$http.post(`${api}/candidates/${this.candidateId}/responses/${this.jobId}`, this.selected);
                }
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            }

            this.$emit("onPost");
        }
    },
    mounted() {
        this.questions.forEach(e => {
            this.selected.push(); 
        });
    }
})
