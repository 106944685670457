import { UUID, NullableString } from './Types';
import Candidate from './Candidate';
import _ from 'lodash';

export enum ApplicantSource {
    Internal = 0,
    AppCast = 1,
    Joveo = 2,
    Reference = 4,
    ClickCast = 8,
    PandoLogic = 16,
    JobSplice = 32,
    Indeed = 64,
    JobTarget = 128,
    JobCase = 256,
    Vonq = 512,
    JobBoardIO = 1024,
    Affiliate = 2048,
    CareerPage = 4096,
    GFJ = 65536
}

export default interface JobApplication {
    jobListingId: UUID
    candidateId: UUID | null
    status: number
    rating: number | null
    resume: NullableString
    trackingRefs: NullableString
    arrivedOn: Date | null
    startedOn: Date | null
    answeredOn: Date | null
    restartedOn: Date | null
    completedOn: Date | null
    candidate: Candidate | null
    source: ApplicantSource | null
    questionScore: number | null
}

export const DefaultApplication: JobApplication = {
    jobListingId: '00000000-0000-0000-0000-000000000000',
    candidateId: null,
    status: 0,
    rating: null,
    resume: null,
    trackingRefs: null,
    arrivedOn: null,
    startedOn: null,
    answeredOn: null,
    restartedOn: null,
    completedOn: null,
    candidate: null,
    source: null,
    questionScore: null
}