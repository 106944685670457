



































import Vue from 'vue';

interface Data {
    isBusy: boolean;
    isInvalid: boolean;
}

export default Vue.extend({
    name: 'LoadCandidateModal',
    props: {
        title: { type: String, required: true },
        instructions: { type: String, required: true }
    },
    data(): Data {
        return {
            isBusy: false,
            isInvalid: false
        }
    },
    methods: {
        loadCandidate() {
            this.$emit('onLoad');
        },
        close() {
            this.$emit('onClose');
        }
    }
})
