





































import Vue from 'vue';
import api from '@/api';
import { UUID, isUUID } from '@/models/Types';
import JobDetails from '@/models/JobDetails';
import JobAbstract, { createAbstract } from '@/models/JobAbstract';
import JobApplication, { DefaultApplication, ApplicantSource } from '@/models/JobApplication';
import { DefaultCandidate } from '@/models/Candidate';
import { QuestionVM } from '@/models/QualQuestion';
// components
import JobHeader from '@/components/modals/JobHeader.vue';
import ApplicantContactInfo from '@/components/quickapply/ApplicantContactInfo.vue';
import ApplicantQualQuestions from '@/components/quickapply/ApplicantQualQuestions.vue';
import ApplicantExperience from '@/components/quickapply/ApplicantExperience.vue';
import ApplicantThankYou from '@/components/quickapply/ApplicantThankYou.vue';
import LoadCandidateModal from '@/components/quickapply/LoadCandidateModal.vue';
import { trackMetaPixelEvent } from '@/common/metaPixel'

interface Data {
    abstract: JobAbstract | null;
    knockouts: QuestionVM[];
    application: JobApplication;
    currentPage: number;
    modal: { title: string, instructions: string },
    priorApp: JobApplication | null;
}

export default Vue.extend({
    name: 'QuickApply',
    components: {
        JobHeader,
        ApplicantContactInfo,
        ApplicantQualQuestions,
        ApplicantExperience,
        ApplicantThankYou,
        LoadCandidateModal
    },
    data(): Data {
        return {
            abstract: null,
            knockouts: [],
            application: { ...DefaultApplication },
            currentPage: 0,
            modal: { title: '', instructions: '' },
            priorApp: null
        }
    },
    computed: {
        id(): string {
            return this.$route.params.id;
        },
        impressionId(): string {
            return this.$route.query.i as string;
        },
        jobId(): UUID {
            return this.abstract!.id;
        },
        payload(): string {
            return this.$route.query.payload as string;
        },
        isPriorApp(): boolean {
            const cnd = this.$route.query.cnd as string;
            return typeof cnd == 'undefined' ? false : true;
        }
    },
    methods: {
         //----------------------------
        // QuickApply navigation events
        next() {
            this.currentPage++;
            // if knockouts are missing, advance again
            if(this.currentPage == 1 && this.knockouts.length == 0){
                this.currentPage++;
            }
            this.$gtag.pageview({ page_path: `/quickapply/current-page-${this.currentPage}` });
        },
        back() {
            this.currentPage--;
            if(this.currentPage == 1 && this.knockouts.length == 0){
                this.currentPage--;
            }
            this.$gtag.pageview({ page_path: `/quickapply/current-page-${this.currentPage}` });
        },
        finish() {
            trackMetaPixelEvent('QA_ApplicantThankYou_loaded', true);
            // this.currentPage = 0;
            if(this.application.source != ApplicantSource.Internal || this.isPriorApp){
                window.location.assign('https://werkberry.com/');
            } else {
                window.history.back();
            }
        },
        close() {
            // this.currentPage = 0;
            window.history.back();
        },
         //------------------------------
        // application submission methods
        async save() {

            if(this.application.source == null) {
                this.application.source = ApplicantSource.Internal;
                this.application.trackingRefs = "source not set";
            }

            trackMetaPixelEvent('QA_ApplicantContactInfo_submit', true);

            const rslt = await this.$http.post(`${api}/candidates/save/${this.jobId}?iid=${this.impressionId}`, this.application);
            this.application = rslt.data;

            this.next();
        },
        async apply() {
            trackMetaPixelEvent('QA_ApplicantExperience_submit', true);
            await this.$http.put(`${api}/candidates/apply/${this.jobId}`, this.application);
            this.next();
        },
         //--------------------
        // data loading methods
        async loadQuestions() {
            // retrieve questions for job
            try {
                const rslt = await this.$http.get(`${api}/questions/${this.jobId}`);
                this.knockouts = rslt.data;
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            }
        },
        async showModal(priorApp: JobApplication | null) {

            if(priorApp != null && priorApp.jobListingId == this.jobId) { // prior app for current job

                this.priorApp = priorApp;

                this.modal.title = 'It looks like you already started this application';
                this.modal.instructions = "Choose 'Load Prior Application' to pick up where you left off. You will need to re-answer any questions you previously answered. Choose 'Ignore Prior Application' to continue with your current application.";

                // } else { // copy from another app
                //     this.modal.title = 'We found your info from a previous application to another job';
                //     this.modal.instructions = "Choose 'Load Prior Application' to load the values you provided last time, or 'Ignore Prior Application' to keep what you've provided so far."
                // }

                this.$bvModal.show('load-candidate-modal')
            } else {
                this.priorApp = null;
                await this.save();
            }
        },
        async loadPriorApp() { // app: JobApplication) {

            const app = this.priorApp as JobApplication;

            this.application.candidateId = app.candidateId;
            this.application.candidate!.id = app.candidateId;
            this.application.startedOn = app.startedOn;
            this.application.arrivedOn = app.arrivedOn;
            this.application.answeredOn = app.answeredOn;

            this.application.candidate!.firstName = app.candidate!.firstName;
            this.application.candidate!.lastName = app.candidate!.lastName;
            this.application.candidate!.email = app.candidate!.email;
            this.application.candidate!.phone = app.candidate!.phone;
            this.application.candidate!.postalCode = app.candidate!.postalCode;

            this.application.resume = app.resume;
            this.application.candidate!.experience = app.candidate!.experience != null && app.candidate!.experience.length > 0 ? app.candidate!.experience : [];
            this.application.candidate!.responses = app.candidate!.responses;

            this.$bvModal.hide('load-candidate-modal')

            await this.save();
        },
        async closeModal() {
            this.application.candidateId = null;
            this.application.candidate!.id = null;
            this.$bvModal.hide('load-candidate-modal')
            await this.save();
        },
        setSource(src: string) {

            switch (src) {
                case 'internal':
                    this.application.source = ApplicantSource.Internal;
                    break;

                case 'ref':
                    this.application.source = ApplicantSource.Reference;
                    this.application.trackingRefs = this.$route.query.ref as string;
                    break;

                case 'appcast':
                    this.application.source = ApplicantSource.AppCast;
                    break;

                case 'joveo':
                    this.application.source = ApplicantSource.Joveo;
                    break;

                case 'clickcast':
                    this.application.source = ApplicantSource.ClickCast;
                    break;

                case 'pando':
                    this.application.source = ApplicantSource.PandoLogic;
                    break;

                case 'jobsplice':
                    this.application.source = ApplicantSource.JobSplice;
                    break;

                case 'indeed':
                    this.application.source = ApplicantSource.Indeed;
                    break;

                case 'jobtarget':
                    this.application.source = ApplicantSource.JobTarget;
                    break;
                
                case 'jobcase':
                    this.application.source = ApplicantSource.JobCase;
                    break;

                case 'vonq':
                    this.application.source = ApplicantSource.Vonq;
                    break;

                case 'jbio':
                    this.application.source = ApplicantSource.JobBoardIO;
                    break;

                case 'afsite':
                    this.application.source = ApplicantSource.Affiliate;
                    break;

                case 'career':
                    this.application.source = ApplicantSource.CareerPage;
                    break;

                case 'gfj':
                    this.application.source = ApplicantSource.GFJ;
                    break;

                default: /* custom */
                    this.application.source = ApplicantSource.Reference;
                    this.application.trackingRefs = JSON.stringify({
                                                        origin: src
                                                    });
                    break;
            }
        }
    },
    async mounted() {

        this.$emit("isExternal");

         //------------------
        // load job from api
        try {

            const rslt = await this.$http.get(`${api}/jobs/campaign/${this.id}`);
            const j = rslt.data as JobDetails;
            this.abstract = createAbstract(j);

            if (this.impressionId == undefined) {                           // SPA Load (internal app)

                console.info('SPA')
                /// if coming from the SPA's job listing page, 
                /// session variables will be set
                let src = sessionStorage.getItem('source');
                let payload = sessionStorage.getItem('payload');

                if(src === undefined) {
                    src = this.$route.query.src as string;
                }

                this.setSource(src as string);
                this.application.trackingRefs = payload;

            } else if (isUUID(this.id)) {                                   // MVC load - Guid

                console.info('MVC guid')
                if(this.$route.query.src != undefined) {

                    sessionStorage.removeItem('source');
                    sessionStorage.removeItem('payload');

                    let src = this.$route.query.src as string;
                    this.setSource(src);
                }

                if(this.payload != undefined) {
                    this.application.trackingRefs = this.payload;
                }

            } else {                                                    // MVC load - Campaign Id   
                console.info('MVC coampaign')
                this.application.source = j.currentSource;
                if(this.payload != undefined) {
                    this.application.trackingRefs = this.payload;
                }
            }

            // retrieve questions for job
            await this.loadQuestions();

        } catch (error) {
            // eslint-disable-next-line
            console.error(`failed to load ${this.id} job.`);
            // eslint-disable-next-line
            console.error(error);
        }

        trackMetaPixelEvent('QA_loaded', true);

         //-------------------------------
        // load previous app if applicable
        if(this.isPriorApp) { // is preload

            const cnd = this.$route.query.cnd as UUID;
            const pg = this.$route.query.pg as string;

            try {
                // search for prior application
                const rslt = await this.$http.get(`${api}/candidates/${cnd}?job=${this.abstract!.id}`);
                this.application = rslt.data as JobApplication;

            } catch (error) {
                // eslint-disable-next-line
                console.error(`failed to load application for ${this.id}.`);
                // eslint-disable-next-line
                console.error(error);

                return null;
            }

            if(typeof pg != 'undefined') {
                this.currentPage = Number.parseInt(pg);
            }

            return;
        }

        if(this.application.source == ApplicantSource.Internal) {
            this.$gtag.pageview({ page_path: '/quickapply/launch-internal' });
        } else {
            this.$gtag.pageview({ page_path: `/quickapply/launch/${this.application.source}/${this.application.trackingRefs}` });
        }

        this.application.jobListingId = this.abstract!.id;
        this.application.candidate = { ...DefaultCandidate }; // _.cloneDeep(DefaultCandidate);
        this.application.arrivedOn = new Date();

    },
    destroyed() {
        sessionStorage.removeItem('source');
        sessionStorage.removeItem('payload');
        this.$emit("onLeaving");
    }
})
