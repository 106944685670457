declare let fbq: any;

export const trackMetaPixelEvent = (
  eventName: string,
  isCustomEvent: boolean,
  payloadName?: string,
  payloadData?: object | null
) => {
  if (!fbq) {
    return;
  }
  const eventType = isCustomEvent ? 'trackCustom' : 'track';

  if (payloadName && payloadData) {
    fbq(eventType, payloadName, { ...payloadData });
    return;
  }
  fbq(eventType, eventName);
};
